import { createRouter, createWebHashHistory } from 'vue-router';

/**
 * 路由
 * */
const routes = [
    {
        path:'/',
        name:'首页',
        component:()=>import("@/pages/page_index")
    },

]
const router = createRouter({
    history:createWebHashHistory(),
    routes
})

export default router
